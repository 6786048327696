<template>
    <div id="container" class="md-layout">
        <h1 class="md-layout-item md-size-80 md-small-size-90">Datenschutz</h1>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    1. Zugriffsdaten und Hosting
                </div>
            </md-card-header>
            <md-card-content>
                Sie können unsere Webseiten besuchen, ohne Angaben zu Ihrer Person zu machen. Bei jedem Aufruf einer Webseite
                speichert der Webserver lediglich automatisch ein sogenanntes Server-Logfile, das z.B. den Namen der angeforderten
                Datei, Ihre IP-Adresse, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und den anfragenden Provider
                (Zugriffsdaten) enthält und den Abruf dokumentiert.
                Diese Zugriffsdaten werden ausschließlich zum Zwecke der Sicherstellung eines störungsfreien Betriebs der Seite
                sowie der Verbesserung unseres Angebots ausgewertet. Dies dient gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO der Wahrung
                unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen an einer korrekten Darstellung
                unseres Angebots. Alle Zugriffsdaten werden spätestens sieben Tage nach Ende Ihres Seitenbesuchs gelöscht.
                <h3>Hostingdienstleistungen durch einen Drittanbieter</h3>
                Im Rahmen einer Verarbeitung in unserem Auftrag erbringt ein Drittanbieter für uns die Dienste zum Hosting und zur
                Darstellung der Webseite. Dies dient der Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden
                berechtigten Interessen an einer korrekten Darstellung unseres Angebots. Alle Daten, die im Rahmen der Nutzung
                dieser Webseite oder in dafür vorgesehenen Formularen im Onlineshop wie folgend beschrieben erhoben werden, werden
                auf seinen Servern verarbeitet. Eine Verarbeitung auf anderen Servern findet nur in dem hier erläuterten Rahmen
                statt.
                Dieser Dienstleister sitzt innerhalb eines Landes der Europäischen Union oder des Europäischen Wirtschaftsraums.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    2. Datenerhebung und -verwendung zur Vertragsabwicklung und bei Eröffnung eines Kundenkontos
                </div>
            </md-card-header>
            <md-card-content>
                Wir erheben personenbezogene Daten, wenn Sie uns diese im Rahmen Ihrer Bestellung, bei einer Kontaktaufnahme mit
                uns (z.B. per Kontaktformular oder E-Mail) oder bei Eröffnung eines Kundenkontos freiwillig mitteilen.
                Pflichtfelder werden als solche gekennzeichnet, da wir in diesen Fällen die Daten zwingend zur Vertragsabwicklung,
                bzw. zur Bearbeitung Ihrer Kontaktaufnahme oder Eröffnung des Kundenkontos benötigen und Sie ohne deren Angabe die
                Bestellung und/ oder die Kontoeröffnung nicht abschließen, bzw. die Kontaktaufnahme nicht versenden können. Welche
                Daten erhoben werden, ist aus den jeweiligen Eingabeformularen ersichtlich. Wir verwenden die von ihnen
                mitgeteilten Daten gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen.
                Nach vollständiger Abwicklung des Vertrages oder Löschung Ihres Kundenkontos werden Ihre Daten für die weitere
                Verarbeitung eingeschränkt und nach Ablauf der steuer- und handelsrechtlichen Aufbewahrungsfristen gelöscht,
                sofern Sie nicht ausdrücklich in eine weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber
                hinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser Erklärung
                informieren. Die Löschung Ihres Kundenkontos ist jederzeit möglich und kann entweder durch eine Nachricht an die
                unten beschriebene Kontaktmöglichkeit oder über eine dafür vorgesehene Funktion im Kundenkonto erfolgen.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    3. Datenweitergabe
                </div>
            </md-card-header>
            <md-card-content>
                Zur Vertragserfüllung gemäß Art. 6 Abs. 1 S. 1 lit. b DSGVO geben wir die nötigen Daten weiter an den ausgewählten
                PAW-Partnerbetrieb.
                <br>
                <br>
                Sobald wir Ihre Daten weitergegeben haben liegt die Verantwortung für deren Verarbeitung beim PAW-Partnerbetrieb.
                Dieser gibt dann seinerseits Teile an hier nicht näher bezeichnete Dritte (z.B. Versanddienstleister) weiter.
                <br>
                <br>
                Folgende Daten des Einlagen-Empfängers geben wir weiter:
                <ul>
                    <li>Vorname</li>
                    <li>Nachname</li>
                    <li>Geburtsdatum</li>
                    <li>Geschlecht</li>
                    <li>Körpergewicht (optionale Angabe)</li>
                    <li>Körpergröße (optionale Angabe)</li>
                    <li>Schuhgröße (optionale Angabe)</li>
                    <li>Kommentar (optionale Angabe)</li>
                    <li>Lieferadresse (optionale Angabe)</li>
                    <li>übermittelte Fußbilder</li>
                </ul>
                <br>
                Folgende Daten des Account-Besitzers geben wir weiter:
                <ul>
                    <li>Adresse (falls keine abweichende Lieferadresse angegeben wird)</li>
                </ul>
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    4. Cookies und Browserspeicher
                </div>
            </md-card-header>
            <md-card-content>
                Um den Besuch unserer Website attraktiv zu gestalten und die Nutzung bestimmter Funktionen zu ermöglichen
                verwenden wir auf verschiedenen Seiten sogenannte Cookies. Dies dient der Wahrung unserer im Rahmen einer
                Interessensabwägung überwiegenden berechtigten Interessen an einer optimierten Darstellung unseres Angebots gemäß
                Art. 6 Abs. 1 S. 1 lit. f DSGVO. Cookies sind kleine Textdateien, die automatisch auf Ihrem Endgerät gespeichert
                werden. Einige der von uns verwendeten Cookies werden nach Ende der Browser-Sitzung, also nach Schließen Ihres
                Browsers, wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf Ihrem Endgerät und ermöglichen
                uns, Ihren Browser beim nächsten Besuch wiederzuerkennen (persistente Cookies). Die Dauer der Speicherung können
                Sie der Übersicht in den Cookie-Einstellungen Ihres Webbrowsers entnehmen. Sie können Ihren Browser so einstellen,
                dass Sie über das Setzen von Cookies informiert werden und einzeln über deren Annahme entscheiden oder die Annahme
                von Cookies für bestimmte Fälle oder generell ausschließen. Jeder Browser unterscheidet sich in der Art, wie er
                die Cookie-Einstellungen verwaltet. Diese ist in dem Hilfemenü jedes Browsers beschrieben, welches Ihnen
                erläutert, wie Sie Ihre Cookie-Einstellungen ändern können. Diese finden Sie für die jeweiligen Browser unter den
                folgenden
                Links:
                <ul>
                    <li>
                        Internet Explorer&trade;:&nbsp;
                        <a href="http://windows.microsoft.com/de-DE/windows-vista/Block-or-allow-cookies">
                            Link zu Microsoft</a>
                    </li>
                    <li>Safari&trade;:&nbsp;
                        <a href="https://support.apple.com/kb/ph21411?locale=de_DE">
                            Link zu Apple</a>
                    </li>
                    <li>Chrome&trade;:&nbsp;
                        <a href="http://support.google.com/chrome/bin/answer.py?hl=de&hlrm=en&answer=95647">
                            Link zu Google</a>
                    </li>
                    <li>Firefox&trade;:&nbsp;
                        <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
                            Link zu Mozilla</a>
                    </li>
                    <li>Opera&trade;:&nbsp;
                        <a href="http://help.opera.com/Windows/10.20/de/cookies.html">
                            Link zu Opera</a>
                    </li>
                </ul>

                Wir verwenden keine Cookies, aber dafür den lokalen Browserspeicher (localStorage),
                sowie den lokalen Sitzungsspeicher (sessionStorage).
                Die Auswahl davon erfolgt abhängig davon, ob Sie sich beim Login entscheiden duerhaft eingeloggt zu bleiben
                oder nicht. Zu keiner Zeit werden Passwörter unverschlüsselt im Browser oder auf dem Server gespeichert!

                <h3>Browserspeicher (localStorage/sessionStorage):</h3>
                Die folgenden Objekte werden gespeichert:
                <ol>
                    <li>
                        <b>Name:</b> token
                        <br>
                        <b>Wert:</b> Token (Schlüssel)
                        <br>
                        <b>Zweck:</b> Dieser Token wird für die Kommunikation mit der Datenbank genutzt. Er wird bei jeder
                        Anmeldung neu generiert.
                        <br>
                        <b>Gültigkeit (localStorage):</b> bis zur Abmeldung
                        <br>
                        <b>Gültigkeit (sessionStorage):</b> bis zur Abmeldung oder zum Sitzungsende
                    </li>
                </ol>
                <ol>
                    <li>
                        <b>Name:</b> id
                        <br>
                        <b>Wert:</b> ID
                        <br>
                        <b>Zweck:</b> Diese ID kennzeichnet den angemeldeten Account. Sie wird benötigt
                        um die eigenen Daten abzurufen.
                        <br>
                        <b>Gültigkeit (localStorage):</b> bis zur Abmeldung
                        <br>
                        <b>Gültigkeit (sessionStorage):</b> bis zur Abmeldung oder zum Sitzungsende
                    </li>
                </ol>

            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    5. Kontaktmöglichkeiten und Ihre Rechte
                </div>
            </md-card-header>
            <md-card-content>
                Als Betroffener haben Sie folgende Rechte:
                <ul>
                    <li>
                        gemäß Art. 15 DSGVO das Recht, in dem dort bezeichneten Umfang Auskunft über Ihre von uns verarbeiteten
                        personenbezogenen Daten zu verlangen;
                    </li>
                    <li>
                        gemäß Art. 16 DSGVO das Recht, unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei
                        uns gespeicherten personenbezogenen Daten zu verlangen;
                    </li>
                    <li>
                        gemäß Art. 17 DSGVO das Recht, die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu
                        verlangen, soweit nicht die weitere Verarbeitung
                        <ul>
                            <li>zur Ausübung des Rechts auf freie Meinungsäußerung und Information;</li>
                            <li>zur Erfüllung einer rechtlichen Verpflichtung;</li>
                            <li>aus Gründen des öffentlichen Interesses oder</li>
                            <li>zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;</li>
                        </ul>
                    </li>
                    <li>
                        gemäß Art. 18 DSGVO das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
                        verlangen, soweit
                        <ul>
                            <li>die Richtigkeit der Daten von Ihnen bestritten wird;</li>
                            <li>die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen;</li>
                            <li>wir die Daten nicht mehr benötigen, Sie diese jedoch zur Geltendmachung, Ausübung oder
                                Verteidigung von Rechtsansprüchen benötigen oder
                            </li>
                            <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;</li>
                        </ul>
                    </li>
                    <li>
                        gemäß Art. 20 DSGVO das Recht, Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
                        strukturierten, gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen
                        Verantwortlichen zu verlangen;gemäß Art. 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu
                        beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes
                        oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
                    </li>
                </ul>
                Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung,
                Sperrung oder Löschung von Daten sowie Widerruf erteilter Einwilligungen oder Widerspruch gegen eine bestimmte
                Datenverwendung wenden Sie sich bitte direkt an uns über die Kontaktdaten in unserem Impressum.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    6. Widerspruchsrecht
                </div>
            </md-card-header>
            <md-card-content>
                Soweit wir zur Wahrung unserer im Rahmen einer Interessensabwägung überwiegenden berechtigten Interessen
                personenbezogene
                Daten wie oben erläutert verarbeiten, können Sie dieser Verarbeitung mit Wirkung für die Zukunft widersprechen.
                Erfolgt
                die Verarbeitung zu Zwecken des Direktmarketings, können Sie dieses Recht jederzeit wie oben beschrieben ausüben.
                Soweit
                die Verarbeitung zu anderen Zwecken erfolgt, steht Ihnen ein Widerspruchsrecht nur bei Vorliegen von Gründen, die
                sich aus
                Ihrer besonderen Situation ergeben, zu.
                Nach Ausübung Ihres Widerspruchsrechts werden wir Ihre personenbezogenen Daten nicht weiter zu diesen Zwecken
                verarbeiten,
                es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                Rechte und
                Freiheiten überwiegen, oder wenn die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von
                Rechtsansprüchen
                dient.
                Dies gilt nicht, wenn die Verarbeitung zu Zwecken des Direktmarketings erfolgt. Dann werden wir Ihre
                personenbezogenen
                Daten nicht weiter zu diesem Zweck verarbeiten.
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
export default {
    name: 'All_PrivacyPolicy',
    props: {
        userdata: null
    },
    data() {
        return {};
    },
    components: {}
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
    align-content: center;
    text-align: center;
}

.md-card {
    text-align: left;
    width: 980px;
    max-width: 90%;
    margin: 10px auto 10px;
    display: inline-block;
    vertical-align: top;
}

h1 {
    text-align: center;
    margin: 10px auto 10px;
}
</style>
